import BroadcastChannelPolyfill from '../infrastructure/BroadcastChannelPolyfill';
import logger from '../infrastructure/logger';

const isBroadcastChannelSupported = () => 'BroadcastChannel' in window;

const addPolyfills = () => {
  if (!isBroadcastChannelSupported()) {
    window.BroadcastChannel = BroadcastChannelPolyfill;
    logger.warn('BroadcastChannel is not supported, using polyfill');
  }
};

export default addPolyfills;
