const productionDependencies = {
  imports: {
    'single-spa': 'https://cdn.jsdelivr.net/npm/single-spa@5.9.5/lib/system/single-spa.min.js',
    react: 'https://unpkg.com/react@18.2.0/umd/react.production.min.js',
    'react-dom': 'https://unpkg.com/react-dom@18.2.0/umd/react-dom.production.min.js',
    lodash: 'https://cdn.jsdelivr.net/npm/lodash@4.17.21/lodash.min.js',
  },
};

const developmentDependencies = {
  imports: {
    'single-spa': 'https://cdn.jsdelivr.net/npm/single-spa@5.9.5/lib/system/single-spa.dev.js',
    react: 'https://unpkg.com/react@18.2.0/umd/react.development.js',
    'react-dom': 'https://unpkg.com/react-dom@18.2.0/umd/react-dom.development.js',
    lodash: 'https://cdn.jsdelivr.net/npm/lodash@4.17.21/lodash.min.js',
  },
};

const coreDependencies = IS_LOCAL_MODE ? developmentDependencies : productionDependencies;

export default coreDependencies;
