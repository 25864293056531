class BroadcastChannelPolyfill implements BroadcastChannel {
  private static readonly channels: Map<string, BroadcastChannelPolyfill[]> = new Map();

  private closed: boolean = false;

  name: string;

  onmessage: any;

  onmessageerror: any;

  constructor(name: string) {
    this.name = name;

    this.addChannel(name);

    window.addEventListener('storage', this.handleStorageEvent.bind(this));
  }

  private addChannel(name: string) {
    if (!BroadcastChannelPolyfill.channels.has(name)) {
      BroadcastChannelPolyfill.channels.set(name, []);
    }

    BroadcastChannelPolyfill.channels.get(name).push(this);
  }

  private static getMessageEvent(message: any): MessageEvent {
    return new MessageEvent('message', { data: message });
  }

  private handleStorageEvent(event: StorageEvent) {
    if (event.key !== this.name || !event.newValue) {
      return;
    }

    const message = JSON.parse(event.newValue);
    const msgEvent = BroadcastChannelPolyfill.getMessageEvent(message);
    this.onmessage!(msgEvent);
  }

  postMessage(message: any) {
    if (this.closed) {
      throw new Error("Failed to execute 'postMessage' on 'BroadcastChannel': Channel is closed");
    }

    const event = BroadcastChannelPolyfill.getMessageEvent(message);
    const channels = BroadcastChannelPolyfill.channels.get(this.name);
    channels.forEach((channel) => {
      if (channel !== this && channel.onmessage) {
        channel.onmessage(event);
      }
    });

    localStorage.setItem(this.name, JSON.stringify(message));
    localStorage.removeItem(this.name);
  }

  close() {
    this.closed = true;

    const channels = BroadcastChannelPolyfill.channels.get(this.name);

    if (!channels) {
      return;
    }

    const index = channels.indexOf(this);

    if (index !== -1) {
      channels.splice(index, 1);
    }

    if (channels.length === 0) {
      BroadcastChannelPolyfill.channels.delete(this.name);
    }
  }

  addEventListener(): void {
    throw new Error(`Method not implemented in ${this.name} channel`);
  }

  dispatchEvent(): boolean {
    throw new Error(`Method not implemented in ${this.name} channel`);
  }

  removeEventListener(): void {
    throw new Error(`Method not implemented in ${this.name} channel`);
  }
}

export default BroadcastChannelPolyfill;
