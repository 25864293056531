import urlsConfig from '../config/urlsConfig';
import { GetImportmapPort } from '../core/Importmap';

const getImportmapUrl = () => `${urlsConfig.importmapUrl}?t=${Date.now()}`;

const handleFetchResponse = (response: Response) => {
  if (!response.ok) {
    throw new Error(`Failed to fetch importmap: ${response.statusText}`);
  }

  return response.json();
};

const fetchImportmap: GetImportmapPort = () => fetch(getImportmapUrl()).then(handleFetchResponse);

export default fetchImportmap;
