const loadScript = (url: string, node: Element) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;

    node.parentNode.insertBefore(script, node.nextSibling);
  });

export default loadScript;
