import coreDependencies from '../config/coreDependencies';
import urlsConfig from '../config/urlsConfig';
import { ImportmapDTO } from '../core/Importmap';
import fetchImportmap from '../infrastructure/fetchImportmap';
import loadScript from '../infrastructure/loadScript';

const addImportMap = (mfeImportmap: ImportmapDTO): Element => {
  window.ABA_IMPORTMAP = mfeImportmap;
  const importmap = {
    imports: {
      ...mfeImportmap.imports,
      ...coreDependencies.imports,
    },
  };
  const importmapNode = document.head.querySelector('script[type="systemjs-importmap"]');
  importmapNode.textContent = JSON.stringify(importmap);
  System.addImportMap(importmap);

  return importmapNode;
};

const loadOverrideScript = (importmapNode: Element) => loadScript(urlsConfig.importOverrideUrl, importmapNode);

const loadRootConfig = () => {
  System.import('@abatech/root-config');
};

const handleImportMapError = (error: Error) => {
  const div = document.createElement('div');
  div.textContent = error.message;
  div.style.color = 'red';
  document.body.appendChild(div);

  throw error;
};

const bootstrapApplication = () =>
  fetchImportmap().then(addImportMap).then(loadOverrideScript).then(loadRootConfig).catch(handleImportMapError);

export default bootstrapApplication;
